@import url('https://fonts.googleapis.com/css2?family=Montserrat&display=swap');

.videoHeading
{
    font-family: "Montserrat";
    color: white;
}
.videoContent
{
    font-family: "Montserrat";
    color: white;
    font-size: 1rem;
    padding-top:20px;
    padding-bottom:10px;
}
.div2 
{
    display: flex;
    flex-wrap: wrap;
}
.div1_MP
{
    background-color: rgba(0,0,0,0.8);
    opacity: 0.6;
    width: 30%;
    text-align: left;
    border:2px solid #ffd79a;
    border-radius: 40px;
    margin-left: 100px;
    padding: 30px;
    box-shadow: 10px 10px 30px #ffe0b2;
    margin-top: 250px;
}
.btn2:hover
{
    transform: scale(1.2);
    border: 2px solid white;
}
.btn1:hover
{
    transform: scale(1.2);
}
.div2_MP
{
    margin-left: 600px;
    margin-top: 350px;
}
video 
{
    position: absolute;
    top: 0;
    left: 0;
    width:100%;
    z-index: -1;
}
.video_wrapper
{
    width: 100%;
    height: 855px;
}
.leapImgHeading
{
    font-weight:900; 
    font-size:1.8rem;
    font-family: "Montserrat";
    color:white;
}

@media (min-width:320px) and (max-width:480px){
    .videoHeading
    {
        font-size: 1.5rem;
        color:black;
    }
    .videoContent
    {
        font-size:1rem;
        color: black;
    }
    .div2
    {
        display: flex;
        flex-wrap: wrap;
        height:680px;
    }
    .div1_MP
    {
        margin:0px;
        margin-top: 270px;
        width:65%; 
        text-align: left;
        background-image: linear-gradient(to top, #faaf93 , white 50%);
        border: 0px;
    }
    .div2_MP
    {
        margin:0px;
        width:35%;
        margin-top: 450px;
        padding-left: 20px;
    }
    img 
    {
        height:80px;
        width:80px;
    }
    video
    {
        position: absolute;
        top: 0;
        left: 0;
        width:100%;
        z-index: -1;
    }   
    .video_wrapper
    {
        width:100%;
        height:100%;
    }
    .responsiveVideo{
        margin-top: 50px;
    }
    .leapImgHeading{
        color:black;
        font-size: medium;
        font-weight: 900;
    }
    .leapDiv{
        margin-top: 50px;
    }
}
@media (min-width:481px) and (max-width:1024px){
    p
    {
        font-size:1.2rem;
    }
    .div2
    {
        width:100%;
        display: flex;
        flex-wrap: wrap;
        padding:40px;
    }
    .div1_MP
    {
        margin:0px;
        margin-top:100px;
        width:65%; 
        text-align: left;
    }
    .div2_MP
    {
        margin:0px;
        margin-top:150px;
        padding-left: 80px;
        width:35%;
    }
    img 
    {
        height:100px;
        width:100px;
    }
    video
    {
        position: absolute;
        top: 0;
        left: 0;
        width:100%;
        z-index: -1;
    }   
    .video_wrapper
    {
        width:100%;
        height:470px;
    }
    .responsiveVideo
    {
        width:100%;
    }
}
@media (min-width:1025px) and (max-width:1210px){
    h1 
    {
        font-size: 1.5rem;
    }
    p
    {
        font-size:1.3rem;
    }
    .div2
    {
        display: flex;
        flex-wrap: wrap;
    }
    .div1_MP
    {
        margin:0px;
        margin-top: 200px;
        margin-left: 100px;
        width:40%; 
        text-align: left;
    }
    .div2_MP
    {
        margin:0px;
        margin-top:260px;
        padding-left:350px;
        width:50%;
    }
    img 
    {
        height:100px;
        width:100px;
    }
    video
    {
        position: absolute;
        top: 0;
        left: 0;
        width:100%;
        z-index: -1;
    }   
    .video_wrapper
    {
        width:100%;
        height:650px;
    }
    .responsiveVideo
    {
        width:100%;
    }
}

.downarrow{
    width: 60px;
    height: 60px;
    margin-left:1300px;
    animation: down 2s infinite;
    animation-delay: 10s;
    cursor: pointer;
}
@keyframes down {
    0% {
        transform: translate(0);
    }
    20% {
        transform: translateY(20px);
    }
    40% {
        transform: translate(0);
    }
}



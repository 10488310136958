.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.navbar{
	background-color: transparent;
}

.navbar.colorChange{
	background-color: #000;
}
#nav-dropdown-dark-example
{
  color: black;
}
#nav-dropdown-dark-example:hover
{
  text-decoration:underline;
  text-decoration-color: #f55014;
  text-decoration-thickness: 5px;
}
.ListItem:hover 
{
  text-decoration: underline;
  text-decoration-color: #f55014;
  text-decoration-thickness: 5px;
}
#navDropdownItem:hover{
  background-image: linear-gradient(to right, rgba(245, 80, 20,0.5) 0%, rgba(255, 209, 59,0.5) 99%);
  color:white;
  font-weight: 900;
}
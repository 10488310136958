@import url('https://fonts.googleapis.com/css2?family=Montserrat&display=swap');

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }
  
  .card {
    transition: 0.3s;
    text-shadow: 10px 20px 20px rgba(0, 0, 0, 0.5);
    overflow-x: hidden;
  }
  
  .card:hover {
    transform: translateX(-2rem);
    transform: scaleX(1.3);
    text-shadow: 10px 20px 20px rgba(0, 0, 0, 0.5);
  }
  
  .card:hover ~ .card {
    transform: scaleX(0.75);
    transform: translateX(3rem);
  }
  
  .card:not(:first-child) {
    margin-left: 10%;
  }
  
  .scroll {
      width: 60px;
      height: 60px;
      border: 2px solid #ffffff;
      border-radius: 50%;
      animation: down 2s infinite;
      animation-delay: 10s;
      cursor: pointer;
  }
  .scroll::before {
          content: '';
          position: absolute;
          top: 15px;
          left: 18px;
          width: 18px;
          height: 18px;
          border-left: 2px solid #ffffff;
          border-bottom: 2px solid #ffffff;
          transform: rotate(-45deg);
      }
  
  @keyframes down {
      0% {
          transform: translate(0);
      }
      20% {
          transform: translateY(20px);
      }
      40% {
          transform: translate(0);
      }
  }
  
  @media only screen and (max-width: 1440px) {
    .card-text h2 {
      line-height: 1.5rem;
    }
  }
  @media only screen and (max-width: 767px){
    .rowLeap{
      flex-direction: column;
    }
    .rowLeap h2:first-of-type{
      font-size: 8rem !important;
    }
    .rowLeap .colPhysical h2{
      margin-top:14vh !important;
    }
    .rowLeap .colPhysical .innerText h2{
      margin-top: 1rem !important;
    }
    .rowLeap .innerText h1{
      font-size: 2.4rem !important;
    }
    .rowLeap .innerText h2{
      font-size:1.5rem !important;
      line-height:1.8rem !important;
    }
    .innerText{
      padding-top: 10vh !important;
    }
    .cardCol div:first-of-type{
      padding:0vh 3vw !important;
    }
    .rowLeap .colPhysical .innerText h1{
      font-size:2.2rem !important;
    }
    .rowLeap .colAnatomy .innerText h1{
      white-space: break-spaces !important;
      line-height: 2rem !important;
    }
    .rowLeap .colAnatomy .innerText{
      margin-left: 3vw !important;
    }
    .rowLeap .colEmotion .innerText{
      width: 70vw !important;
      margin-left: 1vw !important;
    }
    .rowLeap .colPhysical .innerText{
      padding-top: 0vh !important;
    }
    .textBottom{
      text-align: center !important;
      left: 0 !important;
      display: inline !important;
      line-height: 1.8rem !important;
      font-size: 1.6rem !important;
      white-space: break-spaces !important;
      width: 90% ;
      margin: auto !important;
      margin-left: 5vw !important;
    }
    .textTop{
      text-align: center !important;
      display: inline !important;
      line-height: 1.8rem !important;
      font-size: 1.6rem !important;
      white-space: break-spaces !important;
      margin: auto !important;
      margin-top: 10vh !important;
      margin-left: 17vw !important;
      width: 80%;
    }
    .downArrow{
      top:188vh !important;
    }
    .downArrow .arrow-container{
      margin-right: 1vw !important;
    }
    .downArrow .arrow-2{
      right: -2.1vw !important;
    }
    .downArrow .arrow-2 i.fa {
      left: 0 !important;
    }
  }
  
  .rowLeap {
    height: 100%;
  }
  
  .colLifestyle {
    transition: 0.3s;
    background-image: url("../../assets/L.jpg");
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    /* width: 100% !important; */
    padding: 0;
    height: 100%;
  }
  
  .colEmotion {
    transition: 0.3s;
    background-image: url("../../assets/E.jpg");
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    /* width: 100% !important; */
    padding: 0;
    height: 100%;
  }
  
  .colAnatomy {
    transition: 0.3s;
    background-image: url("../../assets/A.jpg");
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    /* width: 100% !important; */
    padding: 0;
    height: 100%;
  }
  
  .colPhysical {
    transition: 0.3s;
    background-image: url("../../assets/P.jpg");
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    /* width: 100% !important; */
    padding: 0;
    height: 100%;
  }
  
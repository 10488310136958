.BoNav{
    position: sticky;
    bottom:0;
    border-top: 1px solid gray;
    border-left: 1px solid gray;
    border-right: 1px solid gray;
    width: max-content;
    border-radius: 10px;
    align-items: center;
    margin-left: 40rem;
    height:min-content;
}
@media (min-width:320px) and (max-width:480px){
    .BoNav
    {
        position: sticky;
        bottom:0;
        left:0;
        border-top: 1px solid gray;
        border-left: 1px solid gray;
        border-right: 1px solid gray;
        border-radius: 10px;
        align-items: center;
        margin:0px;
        margin-top:10px;
        width:100%;
    }
}
@media (min-width:481px) and (max-width:1024px){
    .BoNav
    {
        position: sticky;
        bottom:0;
        left:0;
        border-top: 1px solid gray;
        border-left: 1px solid gray;
        border-right: 1px solid gray;
        border-radius: 10px;
        align-items: center;
        margin:0px;
        margin-top:10px;
        width:100%;
    }
}
@media (min-width:1025px) and (max-width:1210px){
    .BoNav
    {
        position: sticky;
        bottom:0;
        left:0;
        border-top: 1px solid gray;
        border-left: 1px solid gray;
        border-right: 1px solid gray;
        border-radius: 10px;
        align-items: center;
        margin:0px;
        margin-top:10px;
        width:100%;
    }
}

@import url('https://fonts.googleapis.com/css2?family=Montserrat&display=swap');

/* Styles for Moms app and doctors app*/
.container1{
    display: flex;
    flex-wrap: wrap;          
    margin: 0px;
    padding: 0px;
    height:800px;
}
.subcontainer1{
    background-image: radial-gradient(circle farthest-corner at 500px 300px, #faaf93, white 20%, white 50%);    
    margin: 0px;
    padding: 0px;
    width: 50%;
}
.subcontainer2{
    margin: 0px;
    padding-top: 0px;
    height:100%;
    width: 50%;
    background-image: radial-gradient(circle at 45% 58%, #faaf93 30%, #faaf93 30%, white 50%);
}
.subsubcontainer1{
    padding:20px;
    margin-top:250px;
    margin-left:200px;
    width: 50%;
}
.downloadlinks{
    display: flex;
    flex-wrap: wrap;
}
.downloadlink{
    justify-content: right;
    padding:5px;
    height:50px;
    width:150px;
}
.appphoto{
    height:500px; 
    width:230px; 
    margin-top:200px;
    margin-left:230px;
    border-radius: 20px;
}
.applinks{
    animation: applink 2s infinite;
}
@keyframes applink {
    0%{color:black;}
    50%{color:blue;}
    100%{color:#f55014;}
}
.appheading{
    font-family: "Montserrat";
}
.doctorsText{
    color:black;
    font-family: "Montserrat";
    margin:0px;
    height:300px;
    aspect-ratio: 10/5;
}
.momsAppContent{
    color:darkgray;
}
.momsCarousel{
    height:100px;
}
/*Tab and mobile view for Moms and doctors app*/
@media (min-width:320px) and (max-width:480px){
    .container1{
    margin: 0px;
    padding: 0px;
    height:500px;
    }
    .subcontainer1{
        background-image: radial-gradient(circle farthest-corner at 280px 150px, #faaf93, white 20%, white 50%);
        margin:0px;
        padding:0px;    
        width: 100%;
        text-align: center;
    }
    .subcontainer2{
        margin: 0px;
        padding: 0px;
        width: 100%;
        background-image: radial-gradient(circle at 50% 40%, #faaf93 30%, #faaf93 30%, white 50%);
    }
    .subsubcontainer1{
        padding:0px;
        margin:0px;
        margin-left:auto;
        margin-right:auto;
        margin-top:100px;
        width: 80%;
        text-align: center;
        justify-content: center;
    }
    .downloadlinks{
        display:flex;
        flex-wrap: wrap;
        justify-content: center;
    }
    .downloadlink{
        padding:20px;
        height:80px;
    }
    .appheading{
        text-align: center;
    }
    .appphoto{
        height:350px; 
        width:160px; 
        margin-top:30px;
        margin-left:120px;
    }
    .applinks{
        animation: applink 2s infinite;
    }
    @keyframes applink {
        0%{color:black;}
        50%{color:blue;}
        100%{color:#f55014;}
    }
}
@media (min-width:481px) and (max-width:1024px){
    .container1{
        display: flex;
        flex-wrap: wrap;          
        margin: 0px;
        padding: 0px;
        width:100%;
        height:700px;
    }
    .subcontainer1{
        background-image: radial-gradient(circle farthest-corner at 290px 180px, #faaf93, white 20%, white 50%);    
        margin: 0px;
        padding: 0px;
        width: 50%;
    }
    .subcontainer2{
        margin: 0px;
        padding-top: 0px;
        height:100%;
        width: 50%;
        background-image: radial-gradient(circle at 75% 45%, #faaf93 30%, #faaf93 30%, white 50%);
    }
    .subsubcontainer1{
        padding:20px;
        margin-top:100px;
        margin-left:80px;
        width: 50%;
    }
    .downloadlinks{
        display: flex;
        flex-wrap: wrap;
    }
    .downloadlink{
        justify-content: right;
        padding-left:10px;
        width:80px;
        height:40px;
    }
    .appphoto{
        height:400px; 
        width:200px; 
        margin-top:100px;
        margin-left:180px;
    }
    .applinks{
        animation: applink 2s infinite;
    }
    @keyframes applink {
        0%{color:black;}
        50%{color:blue;}
        100%{color:#f55014;}
    }
    .momsCarousel{
        height:120px;
    }
}
@media (min-width:1025px) and (max-width:1210px){
    .container1{
        display: flex;
        flex-wrap: wrap;          
        margin: 0px;
        padding: 0px;
        width:100%;
        height:100%;
    }
    .subcontainer1{
        background-image: radial-gradient(farthest-corner at 400px 330px, #faaf93, white 20%, white 50%);    
        margin: 0px;
        padding: 0px;
        width: 50%;
    }
    .subcontainer2{
        margin: 0px;
        padding-top: 0px;
        height:100%;
        width: 50%;
        background-image: radial-gradient(circle at 55% 58%, #faaf93 30%, #faaf93 30%, white 50%);
    }
    .subsubcontainer1{
        padding:20px;
        margin-top:250px;
        margin-left:80px;
        width: 50%;
    }
    .downloadlinks{
        display: flex;
        flex-wrap: wrap;
    }
    .downloadlink{
        justify-content: left;
        padding-left:10px;
        width:110px;
        height: 50px;
        }
    .appphoto{
        height:500px; 
        width:250px; 
        margin-top:150px;
        margin-left:200px;
    }
    .applinks{
        animation: applink 2s infinite;
    }
    @keyframes applink {
        0%{color:black;}
        50%{color:blue;}
        100%{color:#f55014;}
    }
}

/*Doctors page styles*/
.doctorsContentBlock{
    padding-left:0px;
    padding-right:0px;
}
.doctorsContentHeading{
    font-family: "Montserrat";
    color:black;
    font-weight: 900;
    height:auto;
    aspect-ratio: 14/1;
}
.doctorsContainer1{
    display: flex;
    flex-wrap: wrap;          
    margin: 0px;
    padding: 0px;
    height:500px;
}
.doctorsSubcontainer1{
    background-image: radial-gradient(circle farthest-corner at 550px 200px, #faaf93, white 20%, white 50%);    
    margin: 0px;
    padding: 0px;
    width: 50%;
}
.doctorsSubsubcontainer1{
    padding:0px;
    margin-top:150px;
    margin-left:80px;
    width: 80%;
}
.Doctorappphoto{
    height:500px; 
    width:250px; 
    margin-top:100px;
    margin-left:220px;
    border-radius:20px;
}
.doctorsSubcontainer2{
    margin: 0px;
    padding-top: 0px;
    height:700px;
    width: 50%;
    background-image: radial-gradient(circle at 45% 50%, #faaf93 30%, #faaf93 30%, white 50%);
}
.doctorsDownloadlinks{
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    text-align: center;
}
.doctorsApplinks{
    animation: applink 2s infinite;
    text-align: center;
}
@keyframes doctorsApplink {
    0%{color:black;}
    50%{color:blue;}
    100%{color:#f55014;}
}
.doctorsCarouselCard{
    height:350px;
}
/*Tab and mobile views for doctors page*/
@media (min-width:320px) and (max-width:480px){
    .doctorsContentBlock{
        padding-left:0px;
        padding-right:0px;
    }
    .doctorsContentHeading{
        font-family: "Montserrat";
        color:black;
        font-weight: 900;
        height:150px;
        aspect-ratio: 5/2;
    }
    .doctorsContainer1{
        display: flex;
        flex-wrap: wrap;          
        margin: 0px;
        padding: 0px;
        height:600px;
    }
    .doctorsSubcontainer1{
        background-image: radial-gradient(circle farthest-corner at 300px 200px, #faaf93, white 20%, white 50%);    
        margin: 0px;
        padding: 0px;
        width: 100%;
    }
    .doctorsSubsubcontainer1{
        padding:0px;
        margin-top:150px;
        margin-left:10px;
        width: 100%;
    }
    .doctorsText{
        color:black;
        font-family: "Montserrat";
        margin:0px;
        height:300px;
        aspect-ratio: 6/5;
    }
    .Doctorappphoto{
        height:400px; 
        width:200px; 
        margin-top:50px;
        margin-left:100px;
        border-radius: 20px;
    }
    .doctorsCarouselCard{
        height:500px;
    }
    .Doctorappphoto{
        height:500px; 
        width:250px; 
        margin-top:100px;
        margin-left:70px;
        border-radius:20px;
    }
    .doctorsSubcontainer2{
        margin: 0px;
        padding-top: 0px;
        height:700px;
        width: 100%;
        background-image: radial-gradient(circle at 45% 50%, #faaf93 30%, #faaf93 30%, white 50%);
    }
}
@media (min-width:481px) and (max-width:1024px){
    .doctorsContentBlock{
        padding-left:0px;
        padding-right:0px;
    }
    .doctorsContentHeading{
        font-family: "Montserrat";
        color:black;
        font-weight: 900;
        height:150px;
        aspect-ratio: 6/1;
    }
    .doctorsContainer1{
        display: flex;
        flex-wrap: wrap;          
        margin: 0px;
        padding: 0px;
        height:600px;
    }
    .doctorsSubcontainer1{
        background-image: radial-gradient(circle farthest-corner at 600px 200px, #faaf93, white 20%, white 50%);    
        margin: 0px;
        padding: 0px;
        width: 100%;
        padding-left:80px;
    }
    .doctorsSubsubcontainer1{
        padding:0px;
        margin-top:150px;
        margin-left:10px;
        width: 100%;
    }
    .doctorsText{
        color:black;
        font-family: "Montserrat";
        margin:0px;
        height:300px;
        aspect-ratio: 10/5;
    }
    .Doctorappphoto{
        height:400px; 
        width:200px; 
        margin-top:50px;
        margin-left:100px;
        border-radius: 20px;
    }
    .doctorsCarouselCard{
        height:500px;
    }
    .Doctorappphoto{
        height:500px; 
        width:250px; 
        margin-top:100px;
        margin-left:300px;
        border-radius:20px;
    }
    .doctorsSubcontainer2{
        margin: 0px;
        padding-top: 0px;
        height:700px;
        width: 100%;
        background-image: radial-gradient(circle at 50% 50%, #faaf93 30%, #faaf93 30%, white 50%);
    }
}
@media (min-width:1025px) and (max-width:1210px){
    .doctorsContentBlock{
        padding-left:0px;
        padding-right:0px;
    }
    .doctorsContentHeading{
        font-family: "Montserrat";
        color:black;
        font-weight: 900;
        height:150px;
        aspect-ratio: 6/2;
    }
    .doctorsContainer1{
        display: flex;
        flex-wrap: wrap;          
        margin: 0px;
        padding: 0px;
        height:600px;
    }
    .doctorsSubcontainer1{
        background-image: radial-gradient(circle farthest-corner at 400px 200px, #faaf93, white 20%, white 50%);    
        margin: 0px;
        padding: 0px;
        width: 50%;
    }
    .doctorsSubsubcontainer1{
        padding:0px;
        margin-top:150px;
        margin-left:50px;
        width: 100%;
    }
    .doctorsText{
        color:black;
        font-family: "Montserrat";
        margin:0px;
        height:300px;
        aspect-ratio: 7/5;
    }
    .Doctorappphoto{
        height:500px; 
        width:210px; 
        margin-top:100px;
        margin-left:230px;
        border-radius: 20px;
    }
}

/*Styles for privacy page*/
.privacyheading{
    color: black;
    font-weight: bolder;
    text-decoration: underline;
    text-decoration-color: #f55014;
    text-decoration-thickness: 5px;
    padding-bottom: 20px;
    font-family: "Montserrat";
}
.privacycontainer{
    padding: 40px;
    justify-content: left;
    margin: 50px;
    margin-top:130px;
    border-radius: 40px;
    background-image: linear-gradient(to bottom right, #faaf93, white 50%);
    height:500px;
    overflow-y: scroll;
}
/* width */
.privacycontainer::-webkit-scrollbar {
    width: 10px;
}
  /* Track */
.privacycontainer::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px grey; 
    border-radius: 10px;
} 
  /* Handle */
.privacycontainer::-webkit-scrollbar-thumb {
    background-image: linear-gradient(to right, #f55014 0%, #ffd13b 99%); 
    border-radius: 10px;
}
  /* Handle on hover */
.privacycontainer::-webkit-scrollbar-thumb:hover {
    background: #b30000; 
}

/*Tab and mobile view for privacy page*/
@media (min-width:320px) and (max-width:480px){
    .privacyheading{
        color: black;
        font-weight: bolder;
        text-decoration: underline;
        text-decoration-color: #f55014;
        text-decoration-thickness: 5px;
        padding-bottom: 20px;
        font-size: 1.2rem;
    }
    .privacycontainer{
        padding: 50px;
        justify-content: left;
        margin: 0px;
        margin-top:80px;
        border-radius: 40px;
        background-image: linear-gradient(to bottom, #faaf93, white 50%);
        height:700px;
        overflow-y: scroll;
    }
}
@media (min-width:481px) and (max-width:1024px){
        .privacyheading{
            color: black;
            font-weight: bolder;
            text-decoration: underline;
            text-decoration-color: #f55014;
            text-decoration-thickness: 5px;
            padding-bottom: 20px;
            font-size: 1.5rem;
        }
        .privacycontainer{
            padding: 50px;
            justify-content: left;
            margin: 0px;
            margin-top:80px;
            border-radius: 40px;
            background-image: linear-gradient(to bottom, #faaf93, white 40%);
            height:900px;
            overflow-y: scroll;
        }
}

/*Style for T and C page*/
.TandCheading{
    color: black;
    font-weight: bolder;
    text-decoration: underline;
    text-decoration-color: #f55014;
    text-decoration-thickness: 5px;
    padding-bottom: 20px;
    font-family: "Montserrat";
}
.TandCcontainer{
    padding: 50px;
    justify-content: left;
    margin: 50px;
    margin-top:130px;
    border-radius: 40px;
    background-image: linear-gradient(to bottom right, #faaf93, white 50%);
    height:500px;
    overflow-y: scroll;
}
/* width */
.TandCcontainer::-webkit-scrollbar {
    width: 10px;
}
  /* Track */
.TandCcontainer::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px grey; 
    border-radius: 10px;
} 
  /* Handle */
.TandCcontainer::-webkit-scrollbar-thumb {
    background-image: linear-gradient(to right, #f55014 0%, #ffd13b 99%); 
    border-radius: 10px;
}
  /* Handle on hover */
.TandCcontainer::-webkit-scrollbar-thumb:hover {
    background: #b30000; 
}
/*Mobile and tab view for T and C page */
@media (min-width:320px) and (max-width:480px){
.TandCheading{
    color: black;
    font-weight: bolder;
    text-decoration: underline;
    text-decoration-color: #f55014;
    text-decoration-thickness: 5px;
    padding-bottom: 20px;
    font-size: 1.4rem;
}
.TandCcontainer{
    padding: 50px;
    justify-content: left;
    margin: 0px;
    margin-top:80px;
    border-radius: 40px;
    background-image: linear-gradient(to bottom, #faaf93, white 40%);
    height:700px;
    width:100%;
    overflow-y: scroll;
}
}
@media (min-width:481px) and (max-width:1024px){
    .TandCheading{
        color: black;
        font-weight: bolder;
        text-decoration: underline;
        text-decoration-color: #f55014;
        text-decoration-thickness: 5px;
        padding-bottom: 20px;
        font-size: 1.5rem;
    }
    .TandCcontainer{
        padding: 50px;
        justify-content: left;
        margin: 0px;
        margin-top:80px;
        border-radius: 40px;
        background-image: linear-gradient(to bottom, #faaf93, white 40%);
        height:900px;
        width:100%;
        overflow-y: scroll;
    }
}

/*Leap page styles*/
.leapcontainer{
  display: flex;
  flex-wrap: wrap;
  margin-left:500px;  
  margin-top:100px;
}
.Typo{
    color:black;
    padding-left:50px;
}
.Typo:active{
    color: #f55014;
}

/*Leap component styles*/
.leapcontent{
    font-family: Montserrat;
}
@media (min-width:320px) and (max-width:480px){
.leapcontent{
    font-size:small;
}
}

/*App photos animation*/
/* .slideshow {
    margin: 0 auto;
    overflow: hidden;
    max-width: 500px;
}  
.slide {
    display: inline-block;
    height: 400px;
    width: 100%;
    border-radius: 40px;
}
.slideshowSlider {
    white-space: nowrap;
    transition: ease 1000ms;
} */

/*About Us page Styles*/
.aboutUsMainHeading{
    font-family: "Montserrat";
    text-align: center;
    text-decoration: underline;
    text-decoration-color: #f55014;
    margin-top: 100px;
    font-weight: 900;
}
.aboutUsContainer{
    display: flex;
    flex-wrap: wrap;
    width:100%;
    padding-top:50px;
    padding-right: 100px;
    padding-left: 100px;
    padding-bottom: 20px;
}
.aboutUsHeadingDiv{
    width:50%;
    padding-left: 20px;
    padding-top:20px;
    border-left-width:10px;
    border-left-style: solid;
    border-image: linear-gradient(to bottom, #f55014, #ffd13b) 1 100%;
}
.aboutUsHeading1{
    font-size: 4rem;
    font-family: "Montserrat";
    font-weight: 800;
}
.aboutUsHeading2{
    font-size: 6rem;
    font-family: "Montserrat";
    font-weight: 900;
}
.aboutUsLogoDiv{
    width:50%;
    background-image: radial-gradient(circle farthest-corner at 480px 130px, #faaf93, white 20%, white 50%); 
}
.aboutUsLogoImg{
    padding-left: 420px;
    padding-top: 50px;
    height:200px;
}
.aboutUsContentContainer{
    width:100%;
    margin-bottom: 20px;
}
.aboutUsContent{
    width: 100%;
    padding:20px;
    display: flex;
    flex-wrap: nowrap;
    overflow-x: scroll;
    margin:auto;
}
.aboutUsContent::-webkit-scrollbar {
    height:12px;
} 
.aboutUsContent::-webkit-scrollbar-track {
    background-color: #e4e4e4;
    border-radius: 80px;
} 
.aboutUsContent::-webkit-scrollbar-thumb {
    border-radius: 50px;
    background-image: linear-gradient(to right, #f55014 0%, #ffd13b 99%);
    box-shadow: inset 2px 2px 5px 0 rgba(#fff, 0.5);
}
.aboutUsContent::-webkit-scrollbar-button:single-button {
    background-color: #ece4e4;
    display: block;
    background-size: 10px;
    background-repeat: no-repeat;
}
.aboutUsContent::-webkit-scrollbar-button:single-button:hover {
    background-color: #d0cbcb;
}
.aboutUsContent::-webkit-scrollbar-button:single-button:horizontal:decrement {
    height: 12px;
    width: 50px;
    background-position: 10px 0.5px;
    border-radius: 10px;
    border:1px solid gray;
    background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='100' height='100' fill='rgb(112, 112, 112)'><polygon points='0,50 50,100 50,0'/></svg>");
}
.aboutUsContent::-webkit-scrollbar-button:single-button:horizontal:decrement:active {
    background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='100' height='100' fill='rgb(128, 128, 128)'><polygon points='0,50 50,100 50,0'/></svg>");
}
/* Right */
.aboutUsContent::-webkit-scrollbar-button:single-button:horizontal:increment {
    height: 12px;
    width: 50px;
    background-position: 30px 0.5px;
    border-radius: 10px;
    border:1px solid gray;
    background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='100' height='100' fill='rgb(96, 96, 96)'><polygon points='0,0 0,100 50,50'/></svg>");
}
.aboutUsContent::-webkit-scrollbar-button:single-button:horizontal:increment:active {
    background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='100' height='100' fill='rgb(128, 128, 128)'><polygon points='0,0 0,100 50,50'/></svg>");
}
.contentBlock{
    padding-left:20px;
    padding-right:20px;
} 
.aboutUsText{
    color:black;
    font-family: "Montserrat";
    margin:0px;
    height:120px;
    aspect-ratio: 9/2;
}
.aboutUsText1{
    color:black;
    font-family: "Montserrat";
    height:120px;
    aspect-ratio: 32/4;
}
.aboutUsContentHeading{
    font-family: "Montserrat";
    background:linear-gradient(to right, #f55014, #ffd13b);
    background-clip: text;
    -webkit-text-fill-color: transparent;
    font-weight: 900;
    height:100px;
    aspect-ratio: 6/2;
}
/*Mobile and Tab view for about us page*/
@media (min-width:320px) and (max-width:480px){
    .aboutUsContainer{
        display: flex;
        flex-wrap: wrap;
        width:100%;
        padding-top:50px;
        padding-right: 10px;
        padding-left: 10px;
        padding-bottom: 20px;
    }
    .aboutUsHeadingDiv{
        width:50%;
        padding-left: 20px;
        padding-top:20px;
        border-left-width:10px;
        border-left-style: solid;
        border-image: linear-gradient(to bottom, #f55014, #ffd13b) 1 100%;
    }
    .aboutUsHeading1{
        font-size: 1rem;
        font-family: "Montserrat";
        font-weight: 800;
    }
    .aboutUsHeading2{
        font-size: 3rem;
        font-family: "Montserrat";
        font-weight: 900;
    }
    .aboutUsLogoDiv{
        width:50%;
        background-image: none;
    }
    .aboutUsLogoImg{
        padding-left: 150px;
        padding-top: 0px;
        padding-bottom: 50px;
        height:200px;
        width:200px;
    }
    .aboutUsContentContainer{
        width:100%;
    }
    .aboutUsContent{
        width: 100%;
        padding:10px;
        display: flex;
        flex-wrap: nowrap;
        overflow-x: scroll;
        margin:auto;
        margin-top: 50px;
    }
    .aboutUsContent::-webkit-scrollbar {
        height:5px;
    } 
    .aboutUsContent::-webkit-scrollbar-track {
        background-color: #e4e4e4;
        border-radius: 100px;
    } 
    .aboutUsContent::-webkit-scrollbar-thumb {
        border-radius: 100px;
        background-image: linear-gradient(to right, #f55014 0%, #ffd13b 99%);
        box-shadow: inset 2px 2px 5px 0 rgba(#fff, 0.5);
    }
    .contentBlock{
        padding-left:20px;
        padding-right:20px;
    } 
    .aboutUsText{
        color:black;
        font-family: "Montserrat";
        margin:0px;
        height:200px;
        aspect-ratio: 3/2;
    }
    .aboutUsText1{
        color:black;
        font-family: "Montserrat";
        height:200px;
        aspect-ratio: 15/4;
    }
    .aboutUsContentHeading{
        font-family: "Montserrat";
        background:linear-gradient(to right, #f55014, #ffd13b);
        background-clip: text;
        -webkit-text-fill-color: transparent;
        font-weight: 900;
        height:120px;
        aspect-ratio: 5/2;
    }
}
@media (min-width:481px) and (max-width:1024px){
    .aboutUsContainer{
        display: flex;
        flex-wrap: wrap;
        width:100%;
        padding-top:100px;
        padding-right: 100px;
        padding-left: 100px;
        padding-bottom: 20px;
    }
    .aboutUsHeadingDiv{
        width:50%;
        padding-left: 20px;
        padding-top:20px;
        border-left-width:10px;
        border-left-style: solid;
        border-image: linear-gradient(to bottom, #f55014, #ffd13b) 1 100%;
    }
    .aboutUsHeading1{
        font-size: 2rem;
        font-family: "Montserrat";
        font-weight: 800;
    }
    .aboutUsHeading2{
        font-size: 4rem;
        font-family: "Montserrat";
        font-weight: 900;
    }
    .aboutUsLogoDiv{
        width:50%;
        background-image:none;
    }
    .aboutUsLogoImg{
        padding-left: 100px;
        padding-top: 0px;
        height:200px;
        width:200px;
    }
    .aboutUsContentContainer{
        width:100%;
    }
    .aboutUsContent{
        width: 100%;
        padding:20px;
        display: flex;
        flex-wrap: nowrap;
        overflow-x: scroll;
        margin:auto;
    }
    .aboutUsContent::-webkit-scrollbar {
        height:5px;
    } 
    .aboutUsContent::-webkit-scrollbar-track {
        background-color: #e4e4e4;
        border-radius: 100px;
    } 
    .aboutUsContent::-webkit-scrollbar-thumb {
        border-radius: 100px;
        background-image: linear-gradient(to right, #f55014 0%, #ffd13b 99%);
        box-shadow: inset 2px 2px 5px 0 rgba(#fff, 0.5);
    }
    .contentBlock{
        padding-left:20px;
        padding-right:20px;
    } 
    .aboutUsText{
        color:black;
        font-family: "Montserrat";
        margin:0px;
        height:170px;
        aspect-ratio: 5/2;
    }
    .aboutUsText1{
        color:black;
        font-family: "Montserrat";
        height:170px;
        aspect-ratio: 20/4;
    }
    .aboutUsContentHeading{
        font-family: "Montserrat";
        background:linear-gradient(to right, #f55014, #ffd13b);
        background-clip: text;
        -webkit-text-fill-color: transparent;
        font-weight: 900;
        height:130px;
        aspect-ratio: 6/2;
    }
}
@media (min-width:1025px) and (max-width:1210px){
    .aboutUsContainer{
        display: flex;
        flex-wrap: wrap;
        width:100%;
        padding-top:100px;
        padding-right: 100px;
        padding-left: 100px;
        padding-bottom: 20px;
    }
    .aboutUsHeadingDiv{
        width:50%;
        padding-left: 20px;
        padding-top:20px;
        border-left-width:10px;
        border-left-style: solid;
        border-image: linear-gradient(to bottom, #f55014, #ffd13b) 1 100%;
    }
    .aboutUsHeading1{
        font-size: 4rem;
        font-family: "Montserrat";
        font-weight: 800;
    }
    .aboutUsHeading2{
        font-size: 6rem;
        font-family: "Montserrat";
        font-weight: 900;
    }
    .aboutUsLogoDiv{
        width:50%;
        background-image: none;
    }
    .aboutUsLogoImg{
        padding-left: 420px;
        padding-top: 50px;
        height:100px;
        width:100px;
    }
    .aboutUsContentContainer{
        width:100%;
    }
    .aboutUsContent{
        width: 100%;
        padding:20px;
        display: flex;
        flex-wrap: nowrap;
        overflow-x: scroll;
        margin:auto;
    }
    .aboutUsContent::-webkit-scrollbar {
        height:5px;
    } 
    .aboutUsContent::-webkit-scrollbar-track {
        background-color: #e4e4e4;
        border-radius: 100px;
    } 
    .aboutUsContent::-webkit-scrollbar-thumb {
        border-radius: 100px;
        background-image: linear-gradient(to right, #f55014 0%, #ffd13b 99%);
        box-shadow: inset 2px 2px 5px 0 rgba(#fff, 0.5);
    }
    .contentBlock{
        padding-left:20px;
        padding-right:20px;
    } 
    .aboutUsText{
        color:black;
        font-family: "Montserrat";
        margin:0px;
        height:120px;
        aspect-ratio: 9/2;
    }
    .aboutUsText1{
        color:black;
        font-family: "Montserrat";
        height:120px;
        aspect-ratio: 32/4;
    }
    .aboutUsContentHeading{
        font-family: "Montserrat";
        background:linear-gradient(to right, #f55014, #ffd13b);
        background-clip: text;
        -webkit-text-fill-color: transparent;
        font-weight: 900;
        height:100px;
        aspect-ratio: 6/2;
    }
}

/*Team page styles*/
.teamContainer{
    display:flex;
    flex-wrap: wrap;
    width:100%;
    margin-bottom:20px;
}
.teamContentDiv{
    display: flex;
    flex-direction: column;
    width: 20%;
    margin: 100px;
}
.teamHeading{
    border-left-width: 10px;
    border-left-style: solid;
    border-image: linear-gradient(to bottom, #f55014, #ffd13b) 1 100%;
    font-family: "Montserrat";
    font-weight: 900;
    font-size: 3rem;
    color:black;
    padding-left:10px;
    margin-bottom:20px;
}
.teamContent{
    font-family: "Montserrat";
    color:black;
}
.teamDetailsMainDiv{
    width:60%;
}
.teamDetailsDiv1{
    display:flex;
    flex-wrap: wrap;
    margin-top: 80px;
    margin-bottom: 150px;
}
.teamDetailsDiv2{
    display:flex;
    flex-wrap: wrap;
    margin-bottom: 200px;
}
.teamDetail{
    display:flex;
    flex-direction: column;
    text-align: left;
    padding-left:20px;
    padding-right:20px;
    height: 150px;
    aspect-ratio: 15/8;
}
.teamImg{
    height:130px;
    width:130px;
    border-radius: 50%;
    border: 2px solid gray;
    box-shadow: 2px 2px 5px gray;
    margin-left:auto;
    margin-right:auto;
}
.designation{
    font-family: "Montserrat";
    text-align: center;
    font-weight: 900;
}
.memberName{
    font-family: "Montserrat";
    text-align: center;
    font-weight: 900;
    color: lightgray;
}
.memberDetails{
    color: gray;
}
/*Mobile and Tab view for Team page*/
@media (min-width:320px) and (max-width:480px){
    .teamContainer{
        display:flex;
        flex-wrap: wrap;
        width:100%;
        margin-bottom:20px;
    }
    .teamContentDiv{
        display: flex;
        flex-direction: column;
        width: 100%;
        margin: 20px;
    }
    .teamHeading{
        border-left-width: 10px;
        border-left-style: solid;
        border-image: linear-gradient(to bottom, #f55014, #ffd13b) 1 100%;
        font-family: "Montserrat";
        font-weight: 900;
        font-size: 3rem;
        color:black;
        padding-left:10px;
        margin-bottom:20px;
    }
    .teamContent{
        font-family: "Montserrat";
        color:black;
    }
    .teamDetailsMainDiv{
        width:100%;
    }
    .teamDetailsDiv1{
        display:flex;
        flex-wrap: nowrap;
        margin-top: 80px;
        margin-bottom: 50px;
        overflow-x: scroll;
    }
    .teamDetailsDiv2{
        display:flex;
        flex-wrap: nowrap;
        margin-bottom: 200px;
        overflow-x: scroll;
    }
    .teamDetail{
        display:flex;
        flex-direction: column;
        text-align: left;
        padding-left:20px;
        padding-right:20px;
        height: 300px;
        aspect-ratio: 16/16;
    }
    .teamImg{
        height:130px;
        width:130px;
        border-radius: 50%;
        border: 2px solid gray;
        box-shadow: 2px 2px 5px gray;
        margin-left:auto;
        margin-right:auto;
    }
    .designation{
        font-family: "Montserrat";
        text-align: center;
        font-weight: 900;
    }
    .memberName{
        font-family: "Montserrat";
        text-align: center;
        font-weight: 900;
        color: lightgray;
    }
    .memberDetails{
        color: gray;
    }
}
@media (min-width:481px) and (max-width:1024px){
    .teamContainer{
        display:flex;
        flex-wrap: wrap;
        width:100%;
        margin-bottom:20px;
    }
    .teamContentDiv{
        display: flex;
        flex-direction: column;
        width: 100%;
        margin: 20px;
    }
    .teamHeading{
        border-left-width: 10px;
        border-left-style: solid;
        border-image: linear-gradient(to bottom, #f55014, #ffd13b) 1 100%;
        font-family: "Montserrat";
        font-weight: 900;
        font-size: 3rem;
        color:black;
        padding-left:10px;
        margin-bottom:20px;
    }
    .teamContent{
        font-family: "Montserrat";
        color:black;
    }
    .teamDetailsMainDiv{
        width:100%;
    }
    .teamDetailsDiv1{
        display:flex;
        flex-wrap: nowrap;
        margin-top: 80px;
        margin-bottom: 50px;
        overflow-x: scroll;
    }
    .teamDetailsDiv2{
        display:flex;
        flex-wrap: nowrap;
        margin-bottom: 200px;
        overflow-x: scroll;
    }
    .teamDetail{
        display:flex;
        flex-direction: column;
        text-align: left;
        padding-left:20px;
        padding-right:20px;
        height: 300px;
        aspect-ratio: 16/16;
    }
    .teamImg{
        height:130px;
        width:130px;
        border-radius: 50%;
        border: 2px solid gray;
        box-shadow: 2px 2px 5px gray;
        margin-left:auto;
        margin-right:auto;
    }
    .designation{
        font-family: "Montserrat";
        text-align: center;
        font-weight: 900;
    }
    .memberName{
        font-family: "Montserrat";
        text-align: center;
        font-weight: 900;
        color: lightgray;
    }
    .memberDetails{
        color: gray;
    }
}
@media (min-width:1025px) and (max-width:1210px){
    .teamContainer{
        display:flex;
        flex-wrap: wrap;
        width:100%;
        margin-bottom:20px;
    }
    .teamContentDiv{
        display: flex;
        flex-direction: column;
        width: 100%;
        margin: 20px;
    }
    .teamHeading{
        border-left-width: 10px;
        border-left-style: solid;
        border-image: linear-gradient(to bottom, #f55014, #ffd13b) 1 100%;
        font-family: "Montserrat";
        font-weight: 900;
        font-size: 3rem;
        color:black;
        padding-left:10px;
        margin-bottom:20px;
    }
    .teamContent{
        font-family: "Montserrat";
        color:black;
    }
    .teamDetailsMainDiv{
        width:100%;
        padding-left:150px;
    }
    .teamDetailsDiv1{
        display:flex;
        flex-wrap: nowrap;
        margin-top: 80px;
        margin-bottom: 50px;
        overflow-x: scroll;
    }
    .teamDetailsDiv2{
        display:flex;
        flex-wrap: nowrap;
        margin-bottom: 200px;
        overflow-x: scroll;
    }
    .teamDetail{
        display:flex;
        flex-direction: column;
        text-align: left;
        padding-left:20px;
        padding-right:20px;
        height: 300px;
        aspect-ratio: 16/16;
    }
    .teamImg{
        height:130px;
        width:130px;
        border-radius: 50%;
        border: 2px solid gray;
        box-shadow: 2px 2px 5px gray;
        margin-left:auto;
        margin-right:auto;
    }
    .designation{
        font-family: "Montserrat";
        text-align: center;
        font-weight: 900;
    }
    .memberName{
        font-family: "Montserrat";
        text-align: center;
        font-weight: 900;
        color: lightgray;
    }
    .memberDetails{
        color: gray;
    }
}

/*Contact Us page styles*/
.contactUsMainHeading{
    text-align: center;
    text-decoration: underline;
    font-weight: 900;
    text-decoration-color: #f55014;
    margin-top: 60px;
}
.contactForm{
    margin-top: 30px;
    width:100%;
    padding-left:420px;
    margin-bottom:40px;
}
.contactFormGroup1{
    display:flex;
    flex-wrap: wrap;
}
.contactFormGroup2{
    display:flex;
    flex-wrap: wrap;
}
.contactFieldGroup{
    display: flex;
    flex-direction: column;
    padding:20px;
}
#inputFirstName{
    width: 300px;
    border-radius: 20px;
    background-color: rgba(250, 175, 147,0.6);
}
#inputLastName{
    width: 300px;
    border-radius: 20px;
    background-color: rgba(250, 175, 147,0.6);
}
#inputMail{
    width: 300px;
    border-radius: 20px;
    background-color: rgba(250, 175, 147,0.6);
}
#inputPhone{
    width: 300px;
    border-radius: 20px;
    background-color: rgba(250, 175, 147,0.6);
}
.mb-3{
    width:680px;
    padding:20px;
}
#MessageHelpBlock{
    padding-left: 20px;
    margin: 0px;
}
#contactFormButton{
    background-color:#f55014;
    border:1px solid white;
    border-radius: 20px;
    width: 150px;
    font-weight: 700;
    margin-left: 260px;
    margin-top: 40px;
    box-shadow: 10px 10px 10px gray;
}
#contactFormButton:hover{
    transform: scale(1.2);
}
.contactDetails{
    display: flex;
    flex-wrap:wrap;
    width:100%;
}
.contactDetailsDiv1{
    text-align: center;
    width:30%;
}
.contactDetailsDiv2{
    text-align: center;
    width:40%;
}
.contactDetailsDiv3{
    text-align: center;
    width:30%;
}
.contactDetailsTag{
    font-weight: 900;
    color:#f55014;
    font-family: "Montserrat";
}
.contactHeading{
    font-weight: 900;
    color: black;
    font-family: "Montserrat";
    text-align: center;
}
.snslinks{
    display: flex;
    flex-wrap: wrap;
    margin:0px;
    padding:0px;
    justify-content: center;
}
.snslink:hover{
    transform: scale(1.5);
}
.snslink{
   padding:20px;
   padding-top: 0px;
}
/*Mobile and Tab view for contact us page*/
@media (min-width:320px) and (max-width:480px){
    .contactForm{
        margin-top: 50px;
        width:100%;
        padding-left:50px;
        margin-bottom:50px;
    }
    .contactFormGroup1{
        display:flex;
        flex-wrap: wrap;
    }
    .contactFormGroup2{
        display:flex;
        flex-wrap: wrap;
    }
    .contactFieldGroup{
        display: flex;
        flex-direction: column;
        padding:0px;
    }
    #inputFirstName{
        width: 300px;
        border-radius: 20px;
        background-color: rgba(250, 175, 147,0.6);
    }
    #inputLastName{
        width: 300px;
        border-radius: 20px;
        background-color: rgba(250, 175, 147,0.6);
    }
    #inputMail{
        width: 300px;
        border-radius: 20px;
        background-color: rgba(250, 175, 147,0.6);
    }
    #inputPhone{
        width: 300px;
        border-radius: 20px;
        background-color: rgba(250, 175, 147,0.6);
    }
    .mb-3{
        width:300px;
        padding:0px;
    }
    #MessageHelpBlock{
        padding-left:0px;
        margin: 0px;
    }
    #contactFormButton{
        background-color:#f55014;
        border:1px solid white;
        border-radius: 20px;
        width: 150px;
        font-weight: 700;
        margin-left: 60px;
        margin-top: 40px;
        box-shadow: 10px 10px 10px gray;
    }
    #contactFormButton:hover{
        transform: scale(1.2);
    }
    .contactDetails{
        display: flex;
        flex-direction: column;
        width:100%;
        margin-top: 100px;
    }
    .contactDetailsDiv1{
        text-align: center;
        width:100%;
    }
    .contactDetailsDiv2{
        text-align: center;
        width:100%;
    }
    .contactDetailsDiv3{
        text-align: center;
        width:100%;
    }
    .contactDetailsTag{
        font-weight: 900;
        color:#f55014;
        font-family: "Montserrat";
    }
    .contactHeading{
        font-weight: 900;
        color: black;
        font-family: "Montserrat";
    }
    .snslinks{
        display: flex;
        flex-wrap: wrap;
        margin:0px;
        padding:0px;
    }
    .snslink:hover{
        transform: scale(1.5);
    }
    .snslink{
       padding-left:30px;
    }
}
@media (min-width:481px) and (max-width:1024px){
    .contactUsMainHeading{
        text-align: center;
        text-decoration: underline;
        font-weight: 900;
        text-decoration-color: #f55014;
        margin-top: 80px;
    }
    .contactForm{
        margin-top: 50px;
        width:100%;
        padding-left:80px;
        margin-bottom:50px;
    }
    .contactFormGroup1{
        display:flex;
        flex-wrap: wrap;
    }
    .contactFormGroup2{
        display:flex;
        flex-wrap: wrap;
    }
    .contactFieldGroup{
        display: flex;
        flex-direction: column;
        padding:20px;
    }
    #inputFirstName{
        width: 300px;
        border-radius: 20px;
        background-color: rgba(250, 175, 147,0.6);
    }
    #inputLastName{
        width: 300px;
        border-radius: 20px;
        background-color: rgba(250, 175, 147,0.6);
    }
    #inputMail{
        width: 300px;
        border-radius: 20px;
        background-color: rgba(250, 175, 147,0.6);
    }
    #inputPhone{
        width: 300px;
        border-radius: 20px;
        background-color: rgba(250, 175, 147,0.6);
    }
    .mb-3{
        width:680px;
        padding:20px;
    }
    #MessageHelpBlock{
        padding-left: 20px;
        margin: 0px;
    }
    #contactFormButton{
        background-color:#f55014;
        border:1px solid white;
        border-radius: 20px;
        width: 150px;
        font-weight: 700;
        margin-left: 260px;
        margin-top: 40px;
        box-shadow: 10px 10px 10px gray;
    }
    #contactFormButton:hover{
        transform: scale(1.2);
    }
    .contactDetails{
        display: flex;
        flex-wrap:wrap;
        width:100%;
    }
    .contactDetailsDiv1{
        text-align: center;
        width:30%;
    }
    .contactDetailsDiv2{
        text-align: center;
        width:40%;
    }
    .contactDetailsDiv3{
        text-align: center;
        width:30%;
    }
    .contactDetailsTag{
        font-weight: 900;
        color:#f55014;
        font-family: "Montserrat";
    }
    .contactHeading{
        font-weight: 900;
        color: black;
        font-family: "Montserrat";
    }
    .snslinks{
        display: flex;
        flex-wrap: wrap;
        margin:0px;
        padding:0px;
    }
    .snslink:hover{
        transform: scale(1.5);
    }
    .snslink{
       padding-left:60px;
    }
}
@media (min-width:1025px) and (max-width:1210px){
    .contactForm{
        margin-top: 50px;
        width:100%;
        padding-left:250px;
        margin-bottom:150px;
    }
    .contactFormGroup1{
        display:flex;
        flex-wrap: wrap;
    }
    .contactFormGroup2{
        display:flex;
        flex-wrap: wrap;
    }
    .contactFieldGroup{
        display: flex;
        flex-direction: column;
        padding:20px;
    }
    #inputFirstName{
        width: 300px;
        border-radius: 20px;
        background-color: rgba(250, 175, 147,0.6);
    }
    #inputLastName{
        width: 300px;
        border-radius: 20px;
        background-color: rgba(250, 175, 147,0.6);
    }
    #inputMail{
        width: 300px;
        border-radius: 20px;
        background-color: rgba(250, 175, 147,0.6);
    }
    #inputPhone{
        width: 300px;
        border-radius: 20px;
        background-color: rgba(250, 175, 147,0.6);
    }
    .mb-3{
        width:680px;
        padding:20px;
    }
    #MessageHelpBlock{
        padding-left: 20px;
        margin: 0px;
    }
    #contactFormButton{
        background-color:#f55014;
        border:1px solid white;
        border-radius: 20px;
        width: 150px;
        font-weight: 700;
        margin-left: 260px;
        margin-top: 40px;
        box-shadow: 10px 10px 10px gray;
    }
    #contactFormButton:hover{
        transform: scale(1.2);
    }
    .contactDetails{
        display: flex;
        flex-wrap:wrap;
        width:100%;
        bottom: 0;
        position: fixed;
    }
    .contactDetailsDiv1{
        text-align: center;
        width:30%;
    }
    .contactDetailsDiv2{
        text-align: center;
        width:40%;
    }
    .contactDetailsDiv3{
        text-align: center;
        width:30%;
    }
    .contactDetailsTag{
        font-weight: 900;
        color:#f55014;
        font-family: "Montserrat";
    }
    .contactHeading{
        font-weight: 900;
        color: black;
        font-family: "Montserrat";
    }
    .snslinks{
        display: flex;
        flex-wrap: wrap;
        margin:0px;
        padding:0px;
    }
    .snslink:hover{
        transform: scale(1.5);
    }
    .snslink{
       padding-left:100px;
    }
}